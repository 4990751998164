table {
    display: block;
}

tr {
  display: table-row;
}

td, th {
  display: table-cell;
}


thead {
  display: table-header-group;
}

tbody {
  display: table-row-group;
}

.search-btn {
  position: sticky;
  float: right;
  display: block;
  right: 10px;
  margin-top: -80px;
  top: auto;
  width: 60px;
  height: 60px;
  bottom: 20px;
  border: 3px solid $blue;
  background-color: $green;
  border-radius: 100%;
  padding: 3px;

  box-shadow: 0px 0px 15px -4px #242424;
  transition: box-shadow .1s ease-in-out;

  &:hover {
    box-shadow: 0px 1px 15px -3px #000000;
  }


  &-tablette {
    display: block;
  }

  &-mobile {
    display: none;
  }
}


.search a.search-close {
  bottom: 24px;
  right: 19px;
  transform: scale(1.4) rotate(45deg);
}

@media (min-width: 900px) {
  .search {
    &-field {
      position: relative;
    }
    &-field, ul li {
      max-width: 600px;
      margin: 5px auto;
      left: 0px;
      right: 0px;
    }

    ul {
      padding: 0 5px 10px;
    }

    a.search-close {
      position: absolute;
      bottom: auto;
      top: 50%;
      right: -100px;
      transform: scale(1.4) rotate(45deg) translateY(-50%);

      &:hover {
        right: -100px;
        bottom: 6px;
      }
    }
  }
}
