
.cards {
  .card {
    &.sm-blue a {
      background-color: rgba($blue, 0.8);
      .more {
        background-color: $blue;
      }
    }
    &.sm-green a {
      background-color: rgba(#8aa016, 0.8);
      .more {
        background-color: $green;
      }
    }
  }
}
