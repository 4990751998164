.page {
  @import "project";
  @import "trombi-testimonial";
  @import "contact";
  @import "card";
  @import "documentation";

  #calendar-list {
    .training-date {
      font-size: 1.5rem;
      color: black;
      font-weight: normal;
    }

    .training-title {
      font-size: 1.5rem;
      font-weight: normal;
    }
  }

  .training-list {
    a {
      width: 49%;
    }
  }
}
