.input-field {
  display: block;

  label {
    order: 1;
  }

  input[type="text"],
  input[type="url"],
  input[type="email"],
  input[type="password"],
	input[type="tel"],
	input[type="number"],
  textarea {
		padding: 14px 0 0;
		& + label {
      font-size: 1.4rem;
			position: absolute;
    }

    &:focus + label
    {
      top: 0;
      font-size: 1.4rem;
      color: $grey4;
    }

    &.active + label
    {
      top: 0;
      font-size: 1.4rem;
    }
  }
}
