
.breadcrumb {
  margin-top: 15px;
  margin-bottom: 15px;
  p, ul, li {
    font-size: 1.2rem;
    font-family: $montserrat;
    color: $grey3;
    line-height: 1.8rem;
    display: inline-block;
  }

  p {
    margin-right: 10px;
  }

  li {
    display: inline-block;

    .icon {
      margin: 0 10px;
    }
  }
}
