.trombi-testimonial {


  .trombi-testimonial-header {

    .row {
      position: relative;

      .who {
        position: absolute;
        bottom: 10px;
        right: 0;

        &.noimg {
          position: static;
        }
      }
    }

  }
}

.trombis {
  .trombi {
    min-height: 100px;
  }
}
