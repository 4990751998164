// Colors
$grey-light: #e5e5e5;

$blue-dark: #2e5a6f;
$blue: #3e7e9b;
$blue-light: #60a8c3;

$green-light: #acc81b;
$green: #acc81b;
$green-dark: #9ebf2a;

$black: #3e3e39;

$grey2: #232323;
$grey3: #333333;
$grey4: #434343;
$grey5: #535353;
$grey9: #939393;
$greyc: #c3c3c3;
$greye: #eeeeee;
$greyd: #dddddd;
$greyf: #f4f4f4;

$scorpion: #595757;

$pink: #fc0769;

$nav-width: 270px;

$marcellus: 'Marcellus', serif;
$montserrat: 'Montserrat', sans-serif;
