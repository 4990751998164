
.header  {

  height: 110px;
  margin-bottom: 2px solid green;

  .burger {
    position: absolute;
    top: 25px;
    left: 25px;
    text-indent: -9999px;
    display: inline-block;
    width: 60px;
    height: 60px;

    span {
      top: 2px;
      @include burger(50px, 6px, 13px, $green, 6px);
    }

    &.is-active span {
      @include burger-to-cross;
    }
  }

  h1 {
    width: 214px;
    height: 80px;
    margin-left: 120px;
    margin-top: 13px;
  }

  .fast-link {
    position: fixed;
    top: -20px;
    right: 0px;
    width: 300px;
    border: 0;
    margin: 0;

    a {
      padding-top: 0;
      height: 110px;
      width: 83px;
      position: relative;

      &.active {
        top: 18px;
      }

      .icon-users,
      .icon-file,
      .icon-contactphone {
        position: absolute;
        display: block;
        top: 30px;
        left: 50%;
        transform: translateX(-55%) scale(0.8);
      }


      .icon-file,
      .icon-contactphone {
        left: 52%;
      }


      .icon-fast-link-green,
      .icon-fast-link-blue-dark,
      .icon-fast-link-blue-light {
        position: absolute;
        display: block;
        top: 0;
      }

      div {
        position: absolute;
        left: 5px;
        right: 3px;
        bottom: 5px;
        text-align: center;
        font-size: 1rem;
      }

      &:nth-child(1) div {
        left: 0;
        bottom: 11px;
      }

      &:nth-child(1) {
        background: none;
        z-index: 3;
        transform: translateX(20px);
      }
      &:nth-child(2) {
        background: none;
        z-index: 2;
        transform: translateX(10px);
      }
      &:nth-child(3) {
        background: none;
        z-index: 1;
        transform: translateX(0);
      }

      img {
        display: none;
      }
    }

    &.fast-link-logged a {
        width: 80px;
        margin: auto;
    }
  }

  .fast-link-logged {
    width: 128px;


    a:last-child {
      line-height: 1.3rem;
    }

    .fast-link-logout {
      display: none;
    }
  }

  .logout {
    display: block;
    position: absolute;
    top: 50px;
    right: 128px;
  }

}
