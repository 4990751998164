.footer {
  .footer-top-social {
    top: -45px;
  }

  .handicap {
    margin: auto;
    text-align: center;
    img {
      margin-top: 20px;
      width: 60px;
      height: 60px;
    }
  }

  border-top: 10px solid $green-light;
  padding-top: 15px;

  .footer-top {
    position: relative;

    .container {
      background-image: url('/public/img/footer.svg');
      background-repeat: no-repeat;
      background-size: 65%;
      background-position: bottom left;
    }

    margin-bottom: -1px;

    display: block;

    .footer-menu {
      text-align: left;
      display: flex;
      justify-content: space-between;
      font-family: $montserrat;
      padding: 0 15px 0;

      &,
      a {
        color: white;
      }

      .col {
        li {
          padding: 3px 0 3px;
          font-size: 1.2rem;
        }

        h4 {
          font-size: 1.4rem;
          font-weight: 700;
          padding: 15px 0 0px;
        }

        ul {
          padding: 5px 0 5px 15px;
        }
      }
    }
  }

  .footer-contact {
    height: 170px;
    float: left;
    width: 60%;
    text-align: left;
    background-color: $blue;

    h2,
    p,
    a {
      color: white;
    }

    @include ifmedia(sm) {
      .container {
        width: auto;
      }
    }
  }

  .footer-bottom {
    height: 170px;
    margin: 0 -1px;
    float: left;
    width: 40%;
    padding-top: 15px;
    .fb {
      display: none;
    }

    @include ifmedia(sm) {
      .container {
        width: auto;
      }
    }

    text-align: right;
    li {
      display: block;
      padding: 5px 0;
      a {
        font-size: 1.3rem;
      }
    }
  }
}
