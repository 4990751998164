
@mixin ifmedia($name) {
  @if($name == xs) {
    @media (max-width: 767px) {
      @content;
    }
  }

  @if($name == sm) {
    @media (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  }

  @if($name == md) {
    @media (min-width: 992px) and (max-width: 1199px) {
      @content;
    }
  }

  @if($name == lg) {
    @media (min-width: 1200px) {
      @content;
    }
  }

  @if($name == xssm) {
    @media (max-width: 991px) {
      @content;
    }
  }

  @if($name == xssmmd) {
    @media (max-width: 1199px) {
      @content;
    }
  }

  @if($name == mdlg) {
    @media (min-width: 992px) {
      @content;
    }
  }
}

@mixin ifnotmedia($name) {
  @if($name == xs) {
    @media (min-width: 768px) {
      @content;
    }
  }

  @if($name == sm) {
    @media (max-width: 767px), (min-width: 992px) {
      @content;
    }
  }


  @if($name == md) {
    @media (max-width: 991px), (min-width: 1200px) {
      @content;
    }
  }

  @if($name == lg) {
    @media (max-width: 1199px) {
      @content;
    }
  }
}
