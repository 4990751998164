
.col-carousel {
  margin: 0;
  padding: 0;
}


.homelink {
  max-height: none;

  img {
    width: 100%;
  }

  .homelink-content {
    height: 50px;
    .valign {
      text-align: center;
      margin: auto;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.homelink-blue {
    img {
      transform: translateY(-25px);
    }
    .homelink-content {
      bottom: 0;
    }
  }

  &.homelink-green {
    img {
      transform: translateY(25px);
    }
    .homelink-content {
      top: 0;
    }
  }
}
