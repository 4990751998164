
.student.choice {
  .actions {
    a {
      width: 32%;
      min-height: 100px;
      padding-left: 80px;

      .label {
          font-size: 1.6rem;
      }
    }
  }
}


.left-bg {

  .hidefilter {
    display: block;
  }
}


.album .page  article ul li {
  width: 50%;
}

.album .page.album-show article ul li {
  width: 33%;
}

#top-vue {
  top: -130px;
}
