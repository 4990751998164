
.album {
  img {
    margin: 15px auto;
  }

}

.projects {
  ul {
    display: flex;
    margin: 5px -10px;

    li {
      margin: 5px 10px;
    }
  }
}
