// sm
.error {
  margin-top: 0;

  p, h1, h2 {
    font-size: 2.5em;
  }

  .btn {
    font-size: 1.6rem;
  }
}
