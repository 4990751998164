.articles-list {
  .article {
    padding: 0 15px;
    margin-bottom: 15px;

    &:nth-child(odd) h3 {
      background-color: $blue-light;
    }

    &:nth-child(even) h3 {
      background-color: $green;
    }

    a {
      .article-content {
        display: flex;
        height: auto;

        .article-img {
          padding: 10px;
          width: auto;
          img {
            top: 0;
            position: relative;
            transform: none;
            width: 150px;
          }
        }
        .description {
          width: 100%;
          p {
            width: 100%;
          }
        }
      }
    }
  }
}
